<template>
  <v-container fluid>
    <v-row dense class="mb-3">
      <v-col cols="12">
        <v-tooltip left color="success" v-if="!mostrarFormDireccion">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="primary"
              class="float-right mr-1"
              v-on="on"
              @click="crearDireccion()"
              fab
            >
              <v-icon>mdi-home-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Crear Dirección</span>
        </v-tooltip>
        <v-tooltip left color="red" v-else>
          <template v-slot:activator="{ on }">
            <v-btn
              x-large
              icon
              color="red"
              class="float-right mr-1"
              v-on="on"
              @click="mostrarFormDireccion = false"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Cancelar</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- formulario para alta o edicion de direcciones -->
    <v-expand-transition>
      <ficha-direc-form
        v-if="mostrarFormDireccion"
        @emitCancelar="mostrarFormDireccion = false"
        :propDireccion="direccionEditar"
        :reco_id="reco_id"
      ></ficha-direc-form>
    </v-expand-transition>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          v-if="misDirecciones.length > 0"
          :headers="tabla.headers"
          :items="valores"
          :hide-default-footer="valores.length < 15"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [15, 30, 50, 100],
            'items-per-page-text': 'Filas por página'
          }"
        >
          <template v-slot:[`item.read_idcp`]="{ item }">
            <v-chip label small color="primary">
              {{ item.zipdata.zico_code }} - {{ item.zipdata.zico_town }} ({{
                item.zipdata.zico_province
              }})
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  @click="editarDireccion(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="eliminaDir(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- dialogo para eliminar la direccion al pulsar el icono -->

    <dialog-eliminar
      :mostrar="dialogEliminar.mostrar"
      @emitAceptar="eliminarDireccion"
      @emitCancelar="dialogEliminar.mostrar = false"
      :cabecera="dialogEliminar.cabecera"
      :cuerpo="dialogEliminar.cuerpo"
    ></dialog-eliminar>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import DialogEliminar from "@/components/comun/Dialog";
import FichaDirecForm from "@/components/fichas/parts/forms/FichaDirecForm";

export default {
  name: "ficha-detalle-direcciones",
  props: ["propDirecciones", "reco_id"],
  components: {
    FichaDirecForm,
    DialogEliminar,
  },

  data: () => ({
    dialogEliminar: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
    mostrarDialogEliminar: false,
    mostrarFormDireccion: false,
    direccionEditar: null,
    misDirecciones_: [],
    tabla: {
      headers: [
        {
          text: "id",
          align: " d-none",
          value: "read_id",
        },
        {
          text: "Dirección",
          align: "left",
          value: "read_address",
        },
        {
          text: "Teléfono",
          align: "left",
          value: "read_phone",
        },
        {
          text: "CodPostal | Pob. | Prov",
          align: "left",
          value: "read_idcp",
        },
        {
          text: "main",
          align: " d-none",
          value: "read_main",
        },
        {
          text: "Acciones",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
    },
  }),

  methods: {
    ...mapActions("records", ["setRecordAddressDelete"]),
    ...mapActions("comun", ["setSnackBar"]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),
    crearDireccion() {
      this.mostrarFormDireccion = true;
    },
    editarDireccion(item) {
      //console.log(item);
      this.direccionEditar = item;
      this.mostrarFormDireccion = true;
    },
    /* funcion al pulsar sobre la papelera de una direccion */
    eliminaDir(item) {
      this.dialogEliminar.cabecera = "AVISO";
      this.dialogEliminar.cuerpo =
        "Desea eliminar este registro?<br/>Esta operación no se podrá revertir";
      this.dialogEliminar.id = item.read_id;
      this.dialogEliminar.mostrar = true;
    },
    eliminarDireccion() {
      this.setRecordAddressDelete(this.dialogEliminar.id).then((res) => {
        // console.log(res);
        this.dialogEliminar.cabecera = null;
        this.dialogEliminar.cuerpo = null;
        this.dialogEliminar.id = null;
        this.dialogEliminar.mostrar = false;
        if (!res.success) {
          const miSnack = {
            mostrar: true,
            texto: res.message,
            color: "red",
            timeout: 3000,
          };
          this.setSnackBar(miSnack);
          return;
        }
        this.SET_RECORDS_RELOAD(true);
        this.$emit("emitActualizarDatos", { reco_id: this.reco_id });
      });
    },
  },

  computed: {
    /* valores de la tabla de direcciones */
    valores() {
      if (this.misDirecciones.length > 0) {
        const salida = this.misDirecciones.reduce((acc, curr) => {
          //console.log(curr);
          acc.push({
            read_id: curr.read_id,
            read_address: curr.read_address,
            read_phone: curr.read_phone,
            read_idcp: curr.read_idcp,
            read_phonecta: curr.read_phonecta,
            read_web: curr.read_web,
            read_descuento: curr.read_descuento,
            zipdata: {
              zico_id: curr.read_idcp,
              zico_code: curr.zico_code,
              zico_town: curr.zico_town,
              zico_province: curr.zico_province,
            },
            read_main: curr.read_main === 1 ? true : false,
          });
          return acc;
        }, []);
        return salida;
      }
      return [];
    },
    misDirecciones() {
      if (!this.propDirecciones) {
        return [];
      }
      return this.propDirecciones;
    },
  },
  watch: {
    mostrarFormDireccion(newValue, oldValue) {
      if (newValue === false) {
        this.direccionEditar = null;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
