<template>
  <v-container fluid>
    <div class="class-drag">
      <!-- botones nuevo/cancelar -->
      <v-row>
        <v-col cols="12">
          <!-- botón nuevo fichero -->
          <v-tooltip color="blue" left v-if="!mostrarNuevo">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="mostrarNuevo = !mostrarNuevo"
                class="float-right mr-1"
                color="primary"
                fab
                small
                v-on="on"
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Nuevo fichero adjunto</span>
          </v-tooltip>

          <!-- Botón cancelar -->
          <v-tooltip color="red" left v-else>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="archivoEditar=null; mostrarNuevo = false"
                class="float-right mr-1"
                color="red"
                fab
                small
                v-on="on"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cancelar</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <upload-component
        :reco_id="reco_id"
        :archivo="archivoEditar"
        v-if="mostrarNuevo"
        @createdFile="createdFile"
      ></upload-component>

      <!-- listado de ficheros adjuntos -->
      <v-row v-if="datos.length > 0">
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Fichero</th>
                  <th class="text-left">Comentario</th>
                  <th class="text-center" width="180">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in compMisAdjuntos" :key="item.refi_id">
                  <td>
                    <v-chip color="success" small label outlined>
                      {{ item.refi_name }}</v-chip
                    >
                  </td>
                  <td>{{ item.refi_comment }}</td>
                  <td>
                    <div class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="px-1"
                            color="primary"
                            icon
                            @click="getAttachment(item, false)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                      <v-btn
                        class="px-1"
                        color="primary"
                        icon
                        @click="getAttachment(item)"
                      >
                        <v-icon>mdi-cloud-download</v-icon>
                      </v-btn>
                      <v-btn
                          class="px-1"
                          color="green"
                          icon
                          @click="editarArchivo(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        class="px-1"
                        color="red"
                        icon
                        @click="eliminarArchivo(item.refi_id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- si no hay datos mostramos un alert indicando que aun no  hay registrado nada -->
      <v-row v-else>
        <v-col cols="12">
          <v-alert type="info">
            No hay Ficheros adjuntos para esta ficha.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <!-- dialogo para eliminar un adjunto -->
    <dialog-eliminar
      v-if="dialogEliminar.mostrar"
      :mostrar="dialogEliminar.mostrar"
      @emitAceptar="deleteAttachment"
      @emitCancelar="dialogEliminar.mostrar = false"
      :cabecera="dialogEliminar.cabecera"
      :cuerpo="dialogEliminar.cuerpo"
    ></dialog-eliminar>
  </v-container>
</template>

<script>
import UploadComponent from "./forms/FichaUploadForm";
import { mapActions, mapMutations } from "vuex";
import DialogEliminar from "@/components/comun/Dialog";

export default {
  name: "ficha-detalle-adjuntos",
  props: ["reco_id", "datos"],
  components: {
    UploadComponent,
    DialogEliminar,
  },
  data: () => ({
    mostrarNuevo: false,
    misAdjuntos: [],
    dialogEliminar: {
      id: null,
      mostrar: false,
      cabecera: "Aviso",
      cuerpo:
        "Esta acción no se puede revertir, ¿Desea realmente eliminar el fichero?",
    },
    archivoEditar: null
  }),
  methods: {
    ...mapActions("comun", ["setSnackBar"]),
    ...mapActions("records", ["setDownloadAttachment", "setDeleteAttachment"]),
    ...mapMutations("records", ["SET_RECORD_ADJ"]),
    /* funcion para mostrar el modal de eliminar el adjunto */
    eliminarArchivo(id) {
      console.log("eliminamos el archivo");
      this.dialogEliminar.mostrar = true;
      this.dialogEliminar.id = id;
    },
    editarArchivo(archivo) {
      this.archivoEditar=archivo;
      this.mostrarNuevo=true;
    },
    /* funcion para elimnar un adjunto desde el modal */
    async deleteAttachment() {
      const response = await this.setDeleteAttachment(this.dialogEliminar.id);
      if (response.statusText === "OK") {
        const miSnack = {
          mostrar: true,
          texto: "Eliminado el registro id correctamente",
          color: "info",
        };
        this.setSnackBar(miSnack);
        this.$nextTick(() => {
          this.misAdjuntos = [...response.data.data];
        });
        this.SET_RECORD_ADJ(response.data.data);
        this.dialogEliminar.id = null;
        this.dialogEliminar.mostrar = false;
      }
    },

    /* funcion para descargar el adjunto */
    async getAttachment(file, download = true) {
      const id = file.refi_id;
      const response = await this.setDownloadAttachment(id);
      const type = response.headers["content-type"];

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type })
      );

      const link = document.createElement("a");
      link.href = url;
      //si queremos descargar
      if (download) {
        link.setAttribute("download", file.refi_name);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.download);
      } else {
        link.href = url;
        link.target = "_blank";
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },

    /* funcion para gestionar un fichero creado desde el hijo */
    createdFile(data) {
      //  console.log(data);
      this.archivoEditar=null;
      const miSnack = {
        mostrar: true,
        texto: "Datos guardados correctamente",
        color: "green",
      };
      this.setSnackBar(miSnack);

      this.$nextTick(() => {
        this.misAdjuntos = [...data];
        this.mostrarNuevo = false;
      });
      //this.$emit("emit-actualiza-adj", this.misAdjuntos);
    },
  },
  computed: {
    compMisAdjuntos() {
      return [...this.misAdjuntos];
    },
  },

  mounted() {
    this.misAdjuntos = [...this.datos.filter(f=>f.refi_type===1)];
  },
};
</script>

<style scoped>
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(255, 255, 255);
}
</style>
